<template>
  <v-app id="inspire">
    <router-view/>
    <v-dialog
      v-model="dialog"
      max-width="900px"
    >
      <v-card class="card">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn
          @click="dialog = false"
          rounded
          outlined
          >
            X
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p class="txtt" v-html="$t('changed_rules')"></p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      dark
      fixed
      bottom
      right
      class="button"
      @click="toTop"
    >
      <v-icon>keyboard_arrow_up</v-icon>
    </v-btn>
  </v-app>
</template>

<script>
import { mdiAccount, mdiClose } from '@mdi/js'
// import user from '../store/user'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Dashboard',
  data: () => ({
    icons: {
      mdiAccount,
      mdiClose
    },
    fab: false,
    dialog:true
  }),
  methods:{
    onScroll (e) {
    if (typeof window === 'undefined') return
    const top = window.pageYOffset ||   e.target.scrollTop || 0
    this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    }
  },
  created(){
    // this.user = user.getters.getUser
  },
}
</script>
<style>
@media only screen and (max-width: 480px){
  .special{
    bottom: 0px!important;
  }
  .special{
    right: 70px!important;
  }
}
.card{
  background-color: #ebecd6!important;
  color: #175a33 !important;
  font-family: 'Futura PT' !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 120% !important;
}
.v-sheet.v-card {
    border-radius: 30px!important;
}
.txtt{
  margin-top: 20px;
  color: #175a33 !important;
  font-family: 'Futura PT' !important;
  font-size: 40px !important;
  font-weight: 600 !important;
  line-height: 120% !important;
}
</style>